<template>
  <div>
    客服住院
  </div>
</template>

<script>
export default {
  name: "HospitalService",
  data() {
    return{

    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>

</style>
